import { Component, OnInit } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    items: Array<any> = []

  constructor() {
    this.items = [
      { name: './assets/img/slider1.jpeg' },
      { name: './assets/img/slider2.jpeg' },
      { name: './assets/img/slider3.jpeg' }
    ]
  }
  
  ngOnInit() {
  }

}
