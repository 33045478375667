import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';TestimonialComponent
import { TestimonialComponent } from './testimonial/testimonial.component';

const routes: Routes = [

{
      path: '',
      component: HomeComponent
},{
      path: 'product',
      component: ProductComponent
},{
      path: 'services',
      component: ServicesComponent
},{
      path: 'contact',
      component: ContactComponent
},
{
      path: 'faq',
      component: FaqComponent
},
{
      path: 'about',
      component: AboutComponent
},
{
      path: 'contact',
      component: ContactComponent
},
{
      path: 'testimonial',
      component: TestimonialComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
